<template>
  <div class="feed-body">
    <div class="feed-contents">
      <div class="d-flex flex-row w-100 justify-between mt-5 mb-3">
        <span class="white--text font-30 app-medium-font">
          Donation Details
        </span>
      </div>
      <v-progress-circular
        indeterminate
        color="amber"
        v-if="loading"
        class="mt-10"
      />
      <div v-else class="w-100">
        <v-card @click="goToProfile" class="rounded-lg">
          <div
            class="d-flex align-center justify-start h-100 pa-5 d-flex flex-row"
            v-if="donation && donation.receiver"
          >
            <agency-avatar
              :img="
                donation.receiver.midThumbnail
                  ? donation.receiver.midThumbnail
                  : donation.receiver.image
              "
              :name="donation.receiver.name"
              :size="60"
            />
            <span
              class="primary-color font-20 app-bold-font text-left text-ellipsis break-word line-height-15 force-height-30 d-flex align-center ml-4"
            >
              {{ donation.receiver.name }}
            </span>
          </div>
        </v-card>
        <div class="d-flex w-100 pt-4" v-if="donation">
          <v-card class="w-100">
            <v-card-title>
              <div
                style="width: 200px"
                class="d-flex flex-end justify-end pr-5 font-14"
              >
                Donation Amount:
              </div>
              <span class="font-16" style="width: 100px">
                {{ formatCurrency(donation.pay_in / 100) }}
              </span>
            </v-card-title>
            <v-card-title class="pt-0">
              <div
                style="width: 200px;"
                class="d-flex flex-end justify-end pr-5 font-14"
              >
                Fee:
              </div>
              <span class="font-16">
                {{ formatCurrency((donation.pay_in - donation.pay_out) / 100) }}
              </span>
            </v-card-title>
            <v-divider />
            <v-card-title>
              <div
                style="width: 200px;"
                class="d-flex flex-end justify-end pr-5 font-14"
              >
                Amount agency receive:
              </div>
              <span class="font-16">
                {{ formatCurrency(donation.pay_out / 100) }}
              </span>
            </v-card-title>
          </v-card>
        </div>
        <div class="d-flex w-100 py-4" v-if="donation">
          <v-card class="w-100">
            <v-card-text>
              {{ formatDate(donation.createdAt) }}
            </v-card-text>
            <v-card-text>
              {{ donation.description }}
            </v-card-text>
            <v-card-text>
              {{ donation.status }}: {{ donation.status_description }}
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AgencyAvatar from "@/components/agency/AgencyAvatar.vue";
import dateFormat from "dateformat";

export default {
  components: { AgencyAvatar },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      donation: null,
      amount: null,
      description: "",
    };
  },
  methods: {
    ...mapActions({
      getAgency: "agency/getAgency",
      getDonation: "donation/getDonation",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    goToProfile() {
      let routeData = this.$router.resolve({
        name: "agency-profile-details",
        params: { id: this.$route.query.id },
      });
      window.open(routeData.href, "_blank");
    },
    onRoundAmount() {
      try {
        if (!this.amount) return;
        this.amount = parseFloat(`${this.amount}`).toFixed(2);
      } catch (error) {
        console.log(error);
      }
    },
    goToPayment() {
      this.$router.push({
        name: "donation-payment",
        query: {
          id: this.$route.query.id,
          amount: this.amount,
          description: this.description,
        },
      });
    },
    formatCurrency(numberString) {
      const number = parseFloat(numberString);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(number);
    },
    formatDate(date) {
      return dateFormat(
        this.convertTZ(date, this.timezone),
        "mm/dd/yyyy hh:MM TT"
      );
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
  },
  computed: {
    ...mapGetters({
      timezone: "usersetting/getTimeZone",
    }),
  },
  mounted() {
    this.loading = true;
    this.getDonation({ payment_intent: this.$route.query.payment_intent })
      .then((res) => {
        this.loading = false;
        console.log(res);
        this.donation = res;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
</style>
